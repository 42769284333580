import React from 'react'
import { graphql } from 'gatsby'
import Footer from '../components/footer'
import Seo from '../components/seo'
import Nav from '../components/nav'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const FaqPage = ({data}) => {
  const entry = data.markdownRemark
  const image = getImage(entry.frontmatter.image)
	return (
    <div>
      <Nav />
      <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
      
      <div className="w-full py-36 font-verdana flex-grow-0 flex-shrink-0 basis-full relative">
        <div className="relative z-30 flex items-center justify-center text-center w-full h-full">
          <div className="w-10/12 lg:w-2/3">
            <h1 className="font-bold text-4xl lg:text-6xl text-white leading-none">{entry.frontmatter.heading}</h1>
          </div>
        </div>
        <div className="absolute -inset-0 z-20 w-full h-full bg-black opacity-25" /> 
        <div className="absolute -inset-0 z-10 w-full h-full">
          <GatsbyImage className="w-full h-full object-cover" image={image} alt="" style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} /> 
        </div>
      </div>

      <div className="w-full pt-12 pb-0">
        <div className="w-10/12 mx-auto max-w-screen-2xl">
          <div className="flex justify-around items-center text-center relative">
            <div className="w-10/12 sm:w-8/12 lg:w-8/12 xl:w-8/12">
              <div className="max-w-none font-verdana prose prose-md lg:prose-lg">
                <div dangerouslySetInnerHTML={{ __html: entry.html }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pt-12 pb-12">
        <div className="w-10/12 mx-auto max-w-screen-2xl">
          {entry.frontmatter.faqs.map((entry, i) => {
            return (
              <Disclosure as="div" key={`faq_${i}`} className="w-full mb-6 last:mb-0">
              {({ open }) => (
                <div className="border-2 border-grey p-6">
                  <dt>
                    <Disclosure.Button className="text-left w-full flex justify-between items-start">
                      <h3 className="font-verdana text-black text-2xl inline">
                        {entry.question}
                      </h3>
                      <span className="ml-6 h-7 flex items-center">
                        <ChevronDownIcon
                          className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transition transform inline text-red')}
                          aria-hidden="true"
                        />
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-6 mb-3 pr-12">
                    <div className="max-w-none font-verdana prose text-black prose-lg">
                      {entry.answer}
                    </div>
                  </Disclosure.Panel>
                </div>
              )}
            </Disclosure>
          )})}
        </div>
      </div>
      <div className="w-full py-12 relative">
        <div className="absolute -inset-0">
          <img src="/images/uploads/shutterstock_1083903761.jpg" alt="" className="object-cover object-top w-full h-full block" /> 
        </div>
        <div className="w-10/12 mx-auto max-w-screen-2xl relative">
          <div className="flex justify-around items-center text-center">
            <div className="w-10/12 sm:w-8/12 lg:w-8/12 xl:w-8/12">
              <div className="max-w-none font-verdana prose prose-md lg:prose-2xl text-center">
                <p>{entry.frontmatter.quote} <strong>&mdash; {entry.frontmatter.author}</strong></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
	)
}

export default FaqPage;

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
        title
				page_title
				meta_description
				heading
        image {
					childImageSharp {
            gatsbyImageData(width: 1280)
          }
				}
        faqs {
					question
          answer
				}
        quote
        author
			}
			fields {
				slug
			}
      html
		}
	}
`